exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advance-payment-platform-digipay-plus-js": () => import("./../../../src/pages/advance-payment-platform-digipay-plus.js" /* webpackChunkName: "component---src-pages-advance-payment-platform-digipay-plus-js" */),
  "component---src-pages-agency-banking-agent-cash-in-cash-out-solutions-js": () => import("./../../../src/pages/agency-banking/agent-cash-in-cash-out-solutions.js" /* webpackChunkName: "component---src-pages-agency-banking-agent-cash-in-cash-out-solutions-js" */),
  "component---src-pages-agency-banking-agent-commission-management-js": () => import("./../../../src/pages/agency-banking/agent-commission-management.js" /* webpackChunkName: "component---src-pages-agency-banking-agent-commission-management-js" */),
  "component---src-pages-agency-banking-in-east-africa-js": () => import("./../../../src/pages/agency-banking-in-east-africa.js" /* webpackChunkName: "component---src-pages-agency-banking-in-east-africa-js" */),
  "component---src-pages-agency-banking-solutions-js": () => import("./../../../src/pages/agency-banking-solutions.js" /* webpackChunkName: "component---src-pages-agency-banking-solutions-js" */),
  "component---src-pages-automated-fare-collection-solution-js": () => import("./../../../src/pages/automated-fare-collection-solution.js" /* webpackChunkName: "component---src-pages-automated-fare-collection-solution-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-card-as-a-service-js": () => import("./../../../src/pages/card-as-a-service.js" /* webpackChunkName: "component---src-pages-card-as-a-service-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cashless-payment-solution-for-events-and-conferences-js": () => import("./../../../src/pages/cashless-payment-solution-for-events-and-conferences.js" /* webpackChunkName: "component---src-pages-cashless-payment-solution-for-events-and-conferences-js" */),
  "component---src-pages-cashless-payment-solution-for-restaurants-and-cafe-js": () => import("./../../../src/pages/cashless-payment-solution-for-restaurants-and-cafe.js" /* webpackChunkName: "component---src-pages-cashless-payment-solution-for-restaurants-and-cafe-js" */),
  "component---src-pages-closed-loop-wallet-solution-js": () => import("./../../../src/pages/closed-loop-wallet-solution.js" /* webpackChunkName: "component---src-pages-closed-loop-wallet-solution-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cross-border-payment-solutions-js": () => import("./../../../src/pages/cross-border-payment-solutions.js" /* webpackChunkName: "component---src-pages-cross-border-payment-solutions-js" */),
  "component---src-pages-digipay-top-notch-application-security-js": () => import("./../../../src/pages/digipay-top-notch-application-security.js" /* webpackChunkName: "component---src-pages-digipay-top-notch-application-security-js" */),
  "component---src-pages-digital-fintech-software-solution-js": () => import("./../../../src/pages/digital-fintech-software-solution.js" /* webpackChunkName: "component---src-pages-digital-fintech-software-solution-js" */),
  "component---src-pages-digital-fintech-solutions-for-banks-js": () => import("./../../../src/pages/digital-fintech-solutions-for-banks.js" /* webpackChunkName: "component---src-pages-digital-fintech-solutions-for-banks-js" */),
  "component---src-pages-digital-fintech-solutions-for-nbfcs-js": () => import("./../../../src/pages/digital-fintech-solutions-for-nbfcs.js" /* webpackChunkName: "component---src-pages-digital-fintech-solutions-for-nbfcs-js" */),
  "component---src-pages-digital-fintech-solutions-for-telcos-js": () => import("./../../../src/pages/digital-fintech-solutions-for-telcos.js" /* webpackChunkName: "component---src-pages-digital-fintech-solutions-for-telcos-js" */),
  "component---src-pages-digital-payment-solutions-for-fintechs-js": () => import("./../../../src/pages/digital-payment-solutions-for-fintechs.js" /* webpackChunkName: "component---src-pages-digital-payment-solutions-for-fintechs-js" */),
  "component---src-pages-digital-wallet-cryptocurrency-wallet-app-development-solutions-js": () => import("./../../../src/pages/digital-wallet/cryptocurrency-wallet-app-development-solutions.js" /* webpackChunkName: "component---src-pages-digital-wallet-cryptocurrency-wallet-app-development-solutions-js" */),
  "component---src-pages-digital-wallet-evolution-of-digital-wallet-and-mobile-payment-js": () => import("./../../../src/pages/digital-wallet/evolution-of-digital-wallet-and-mobile-payment.js" /* webpackChunkName: "component---src-pages-digital-wallet-evolution-of-digital-wallet-and-mobile-payment-js" */),
  "component---src-pages-digital-wallet-solution-js": () => import("./../../../src/pages/digital-wallet-solution.js" /* webpackChunkName: "component---src-pages-digital-wallet-solution-js" */),
  "component---src-pages-download-company-profile-js": () => import("./../../../src/pages/download-company-profile.js" /* webpackChunkName: "component---src-pages-download-company-profile-js" */),
  "component---src-pages-ekyc-address-verification-js": () => import("./../../../src/pages/ekyc/address-verification.js" /* webpackChunkName: "component---src-pages-ekyc-address-verification-js" */),
  "component---src-pages-ekyc-aml-screening-js": () => import("./../../../src/pages/ekyc/aml-screening.js" /* webpackChunkName: "component---src-pages-ekyc-aml-screening-js" */),
  "component---src-pages-ekyc-as-a-service-js": () => import("./../../../src/pages/ekyc-as-a-service.js" /* webpackChunkName: "component---src-pages-ekyc-as-a-service-js" */),
  "component---src-pages-ekyc-document-verification-js": () => import("./../../../src/pages/ekyc/document-verification.js" /* webpackChunkName: "component---src-pages-ekyc-document-verification-js" */),
  "component---src-pages-ekyc-face-verification-js": () => import("./../../../src/pages/ekyc/face-verification.js" /* webpackChunkName: "component---src-pages-ekyc-face-verification-js" */),
  "component---src-pages-ekyc-pricing-js": () => import("./../../../src/pages/ekyc/pricing.js" /* webpackChunkName: "component---src-pages-ekyc-pricing-js" */),
  "component---src-pages-ekyc-solution-js": () => import("./../../../src/pages/ekyc-solution.js" /* webpackChunkName: "component---src-pages-ekyc-solution-js" */),
  "component---src-pages-ekyc-transforming-experience-for-banks-js": () => import("./../../../src/pages/ekyc/transforming-experience-for-banks.js" /* webpackChunkName: "component---src-pages-ekyc-transforming-experience-for-banks-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-evolution-of-cross-border-remittances-whitepaper-js": () => import("./../../../src/pages/evolution-of-cross-border-remittances-whitepaper.js" /* webpackChunkName: "component---src-pages-evolution-of-cross-border-remittances-whitepaper-js" */),
  "component---src-pages-ewallet-resort-and-hotel-solution-for-hospitality-js": () => import("./../../../src/pages/ewallet-resort-and-hotel-solution-for-hospitality.js" /* webpackChunkName: "component---src-pages-ewallet-resort-and-hotel-solution-for-hospitality-js" */),
  "component---src-pages-ewallet-solution-entertainment-and-amusement-park-js": () => import("./../../../src/pages/ewallet-solution/entertainment-and-amusement-park.js" /* webpackChunkName: "component---src-pages-ewallet-solution-entertainment-and-amusement-park-js" */),
  "component---src-pages-fintech-digital-payments-in-nigeria-js": () => import("./../../../src/pages/fintech-digital-payments-in-nigeria.js" /* webpackChunkName: "component---src-pages-fintech-digital-payments-in-nigeria-js" */),
  "component---src-pages-fraud-prevention-js": () => import("./../../../src/pages/fraud-prevention.js" /* webpackChunkName: "component---src-pages-fraud-prevention-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-remittance-agent-network-module-js": () => import("./../../../src/pages/international-remittance/agent-network-module.js" /* webpackChunkName: "component---src-pages-international-remittance-agent-network-module-js" */),
  "component---src-pages-international-remittance-cross-border-bill-payments-js": () => import("./../../../src/pages/international-remittance/cross-border-bill-payments.js" /* webpackChunkName: "component---src-pages-international-remittance-cross-border-bill-payments-js" */),
  "component---src-pages-international-remittance-features-js": () => import("./../../../src/pages/international-remittance/features.js" /* webpackChunkName: "component---src-pages-international-remittance-features-js" */),
  "component---src-pages-international-remittance-multiple-corridors-and-exchange-rate-management-js": () => import("./../../../src/pages/international-remittance/multiple-corridors-and-exchange-rate-management.js" /* webpackChunkName: "component---src-pages-international-remittance-multiple-corridors-and-exchange-rate-management-js" */),
  "component---src-pages-international-remittance-price-based-smart-routing-js": () => import("./../../../src/pages/international-remittance/price-based-smart-routing.js" /* webpackChunkName: "component---src-pages-international-remittance-price-based-smart-routing-js" */),
  "component---src-pages-loyalty-and-rewards-solutions-js": () => import("./../../../src/pages/loyalty-and-rewards-solutions.js" /* webpackChunkName: "component---src-pages-loyalty-and-rewards-solutions-js" */),
  "component---src-pages-lp-mobile-money-payment-solutions-js": () => import("./../../../src/pages/lp/mobile-money-payment-solutions.js" /* webpackChunkName: "component---src-pages-lp-mobile-money-payment-solutions-js" */),
  "component---src-pages-merchant-acquiring-features-js": () => import("./../../../src/pages/merchant-acquiring/features.js" /* webpackChunkName: "component---src-pages-merchant-acquiring-features-js" */),
  "component---src-pages-merchant-acquiring-solution-js": () => import("./../../../src/pages/merchant-acquiring-solution.js" /* webpackChunkName: "component---src-pages-merchant-acquiring-solution-js" */),
  "component---src-pages-merchant-management-invoice-payments-js": () => import("./../../../src/pages/merchant-management/invoice-payments.js" /* webpackChunkName: "component---src-pages-merchant-management-invoice-payments-js" */),
  "component---src-pages-merchant-management-merchant-payment-solutions-js": () => import("./../../../src/pages/merchant-management/merchant-payment-solutions.js" /* webpackChunkName: "component---src-pages-merchant-management-merchant-payment-solutions-js" */),
  "component---src-pages-merchant-management-qr-payments-js": () => import("./../../../src/pages/merchant-management/qr-payments.js" /* webpackChunkName: "component---src-pages-merchant-management-qr-payments-js" */),
  "component---src-pages-merchant-management-tap-to-phone-js": () => import("./../../../src/pages/merchant-management/tap-to-phone.js" /* webpackChunkName: "component---src-pages-merchant-management-tap-to-phone-js" */),
  "component---src-pages-micro-finance-solutions-js": () => import("./../../../src/pages/micro-finance-solutions.js" /* webpackChunkName: "component---src-pages-micro-finance-solutions-js" */),
  "component---src-pages-mobile-money-bulk-payments-for-ewallet-js": () => import("./../../../src/pages/mobile-money/bulk-payments-for-ewallet.js" /* webpackChunkName: "component---src-pages-mobile-money-bulk-payments-for-ewallet-js" */),
  "component---src-pages-mobile-money-ewallet-for-eticket-booking-payment-solutions-js": () => import("./../../../src/pages/mobile-money/ewallet-for-eticket-booking-payment-solutions.js" /* webpackChunkName: "component---src-pages-mobile-money-ewallet-for-eticket-booking-payment-solutions-js" */),
  "component---src-pages-mobile-money-ewallet-for-parking-payment-solutions-js": () => import("./../../../src/pages/mobile-money/ewallet-for-parking-payment-solutions.js" /* webpackChunkName: "component---src-pages-mobile-money-ewallet-for-parking-payment-solutions-js" */),
  "component---src-pages-mobile-money-ewallet-for-toll-road-payment-solutions-js": () => import("./../../../src/pages/mobile-money/ewallet-for-toll-road-payment-solutions.js" /* webpackChunkName: "component---src-pages-mobile-money-ewallet-for-toll-road-payment-solutions-js" */),
  "component---src-pages-mobile-money-ewallet-fuel-solutions-js": () => import("./../../../src/pages/mobile-money/ewallet-fuel-solutions.js" /* webpackChunkName: "component---src-pages-mobile-money-ewallet-fuel-solutions-js" */),
  "component---src-pages-mobile-money-features-js": () => import("./../../../src/pages/mobile-money/features.js" /* webpackChunkName: "component---src-pages-mobile-money-features-js" */),
  "component---src-pages-mobile-money-host-card-emulation-for-ewallet-js": () => import("./../../../src/pages/mobile-money/host-card-emulation-for-ewallet.js" /* webpackChunkName: "component---src-pages-mobile-money-host-card-emulation-for-ewallet-js" */),
  "component---src-pages-mobile-money-payment-solutions-js": () => import("./../../../src/pages/mobile-money-payment-solutions.js" /* webpackChunkName: "component---src-pages-mobile-money-payment-solutions-js" */),
  "component---src-pages-mobile-money-pricing-js": () => import("./../../../src/pages/mobile-money/pricing.js" /* webpackChunkName: "component---src-pages-mobile-money-pricing-js" */),
  "component---src-pages-mpos-mobile-point-of-sale-solutions-js": () => import("./../../../src/pages/mpos-mobile-point-of-sale-solutions.js" /* webpackChunkName: "component---src-pages-mpos-mobile-point-of-sale-solutions-js" */),
  "component---src-pages-mpos-solution-js": () => import("./../../../src/pages/mpos-solution.js" /* webpackChunkName: "component---src-pages-mpos-solution-js" */),
  "component---src-pages-our-usp-js": () => import("./../../../src/pages/our-usp.js" /* webpackChunkName: "component---src-pages-our-usp-js" */),
  "component---src-pages-pocket-money-solution-for-managing-children-finances-js": () => import("./../../../src/pages/pocket-money-solution-for-managing-children-finances.js" /* webpackChunkName: "component---src-pages-pocket-money-solution-for-managing-children-finances-js" */),
  "component---src-pages-prepaid-card-retail-revolution-js": () => import("./../../../src/pages/prepaid-card/retail-revolution.js" /* webpackChunkName: "component---src-pages-prepaid-card-retail-revolution-js" */),
  "component---src-pages-prepaid-cards-solution-js": () => import("./../../../src/pages/prepaid-cards-solution.js" /* webpackChunkName: "component---src-pages-prepaid-cards-solution-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-thank-you-for-scheduling-meeting-js": () => import("./../../../src/pages/thank-you-for-scheduling-meeting.js" /* webpackChunkName: "component---src-pages-thank-you-for-scheduling-meeting-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-top-up-and-bill-payment-solutions-js": () => import("./../../../src/pages/top-up-and-bill-payment-solutions.js" /* webpackChunkName: "component---src-pages-top-up-and-bill-payment-solutions-js" */),
  "component---src-pages-wallet-as-a-service-js": () => import("./../../../src/pages/wallet-as-a-service.js" /* webpackChunkName: "component---src-pages-wallet-as-a-service-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-whitepapers-js": () => import("./../../../src/pages/whitepapers.js" /* webpackChunkName: "component---src-pages-whitepapers-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-2021-year-review-digipay-guru-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/2021-year-review-digipay-guru/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-2021-year-review-digipay-guru-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-a-guide-to-mobile-payment-systems-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/a-guide-to-mobile-payment-systems/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-a-guide-to-mobile-payment-systems-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-advanced-bulk-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/advanced-bulk-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-advanced-bulk-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-agency-banking-as-alternative-business-for-banks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/agency-banking-as-alternative-business-for-banks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-agency-banking-as-alternative-business-for-banks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-agency-banking-in-kenya-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/agency-banking-in-kenya/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-agency-banking-in-kenya-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-agents-in-remittance-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/agents-in-remittance-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-agents-in-remittance-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-all-about-financial-inclusion-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/all-about-financial-inclusion/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-all-about-financial-inclusion-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-agency-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/all-you-need-to-know-about-agency-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-agency-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-cross-border-bill-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/all-you-need-to-know-about-cross-border-bill-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-cross-border-bill-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-nfc-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/all-you-need-to-know-about-nfc-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-all-you-need-to-know-about-nfc-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-apis-in-fintech-and-banking-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/apis-in-fintech-and-banking-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-apis-in-fintech-and-banking-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automated-identity-verification-with-digipay-guru-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/automated-identity-verification-with-digipay-guru/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automated-identity-verification-with-digipay-guru-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-and-impact-of-nfc-card-on-contactless-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/benefits-and-impact-of-nfc-card-on-contactless-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-and-impact-of-nfc-card-on-contactless-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-going-cashless-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/benefits-of-going-cashless/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-going-cashless-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-liveness-detection-for-fraud-prevention-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/benefits-of-liveness-detection-for-fraud-prevention/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-liveness-detection-for-fraud-prevention-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-smart-parking-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/benefits-of-smart-parking-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-smart-parking-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-money-transfer-exchange-rate-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/best-money-transfer-exchange-rate/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-money-transfer-exchange-rate-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-financial-inclusion-with-cross-border-payment-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/boost-financial-inclusion-with-cross-border-payment-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-financial-inclusion-with-cross-border-payment-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-financial-inclusion-with-mobile-wallets-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/boost-financial-inclusion-with-mobile-wallets/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-financial-inclusion-with-mobile-wallets-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-loyalty-security-with-prepaid-card-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/boost-loyalty-security-with-prepaid-card-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-loyalty-security-with-prepaid-card-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-branchless-banking-facilitating-financial-inclusion-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/branchless-banking-facilitating-financial-inclusion/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-branchless-banking-facilitating-financial-inclusion-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-buy-now-pay-later-changing-the-way-credit-works-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/buy-now-pay-later-changing-the-way-credit-works/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-buy-now-pay-later-changing-the-way-credit-works-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cashless-payment-solution-for-fuel-retail-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cashless-payment-solution-for-fuel-retail-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cashless-payment-solution-for-fuel-retail-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-and-solutions-of-financial-inclusion-in-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/challenges-and-solutions-of-financial-inclusion-in-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-and-solutions-of-financial-inclusion-in-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cheerful-celebration-of-digipay-event-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cheerful-celebration-of-digipay-event/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cheerful-celebration-of-digipay-event-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-the-ideal-agency-banking-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/choosing-the-ideal-agency-banking-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-the-ideal-agency-banking-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-and-open-loop-wallet-for-merchants-and-customers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/closed-loop-and-open-loop-wallet-for-merchants-and-customers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-and-open-loop-wallet-for-merchants-and-customers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/closed-loop-payment-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-vs-open-vs-semi-open-loop-prepaid-cards-explained-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/closed-vs-open-vs-semi-open-loop-prepaid-cards-explained/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-vs-open-vs-semi-open-loop-prepaid-cards-explained-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-technology-for-digital-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cloud-technology-for-digital-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-technology-for-digital-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-prepaid-cards-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/comprehensive-guide-to-prepaid-cards/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-prepaid-cards-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-contactless-payment-solution-for-retail-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/contactless-payment-solution-for-retail-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-contactless-payment-solution-for-retail-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-contactless-payments-solutions-during-covid-19-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/contactless-payments-solutions-during-covid-19/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-contactless-payments-solutions-during-covid-19-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-convenience-security-with-digital-wallet-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/convenience-security-with-digital-wallet-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-convenience-security-with-digital-wallet-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-payments-opportunities-challenges-for-small-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cross-border-payments-opportunities-challenges-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-payments-opportunities-challenges-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-remittance-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cross-border-remittance-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-remittance-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-remittance-solution-in-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cross-border-remittance-solution-in-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-remittance-solution-in-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-transactions-and-international-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/cross-border-transactions-and-international-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cross-border-transactions-and-international-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-customer-experience-transformation-in-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/customer-experience-transformation-in-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-customer-experience-transformation-in-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-customer-loyalty-programs-on-your-mobile-wallets-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/customer-loyalty-programs-on-your-mobile-wallets/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-customer-loyalty-programs-on-your-mobile-wallets-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-difference-between-mobile-wallets-and-mobile-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/difference-between-mobile-wallets-and-mobile-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-difference-between-mobile-wallets-and-mobile-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-advanced-fuel-payment-solution-help-fuel-retail-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-advanced-fuel-payment-solution-help-fuel-retail-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-advanced-fuel-payment-solution-help-fuel-retail-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-fulfils-client-vision-of-user-friendly-mobile-payment-application-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-fulfils-client-vision-of-user-friendly-mobile-payment-application/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-fulfils-client-vision-of-user-friendly-mobile-payment-application-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-fintech-surge-gitex-global-week-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-at-fintech-surge-gitex-global-week/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-fintech-surge-gitex-global-week-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-fintech-week-london-2023-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-at-fintech-week-london-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-fintech-week-london-2023-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-seamless-africa-2022-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-at-seamless-africa-2022/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-seamless-africa-2022-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-seamless-north-africa-2023-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-at-seamless-north-africa-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-seamless-north-africa-2023-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-wfis-kenya-2023-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-at-wfis-kenya-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-at-wfis-kenya-2023-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-attended-seamless-middle-east-2021-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-attended-seamless-middle-east-2021/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-attended-seamless-middle-east-2021-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-ceo-interview-in-app-story-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-ceo-interview-in-app-story/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-ceo-interview-in-app-story-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-cross-border-payment-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-cross-border-payment-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-cross-border-payment-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-highlights-seamless-africa-2023-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-highlights-seamless-africa-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-highlights-seamless-africa-2023-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-is-going-to-fintech-surge-at-gitex-global-week-old-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-is-going-to-fintech-surge-at-gitex-global-week-old/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-is-going-to-fintech-surge-at-gitex-global-week-old-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-provides-advance-e-wallet-solution-in-ivory-coast-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-provides-advance-e-wallet-solution-in-ivory-coast/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-provides-advance-e-wallet-solution-in-ivory-coast-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-provides-wallet-solution-in-bahamas-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-guru-provides-wallet-solution-in-bahamas/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-guru-provides-wallet-solution-in-bahamas-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-helps-client-become-leading-fintech-firm-in-philippines-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-helps-client-become-leading-fintech-firm-in-philippines/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-helps-client-become-leading-fintech-firm-in-philippines-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-admin-top-up-feature-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-introduces-admin-top-up-feature/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-admin-top-up-feature-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-advanced-bulk-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-introduces-advanced-bulk-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-advanced-bulk-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-advanced-features-for-digital-wallet-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-introduces-advanced-features-for-digital-wallet/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-advanced-features-for-digital-wallet-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-customer-loyalty-programs-in-mobile-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-introduces-customer-loyalty-programs-in-mobile-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-customer-loyalty-programs-in-mobile-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-virtual-prepaid-card-feature-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digipay-introduces-virtual-prepaid-card-feature/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digipay-introduces-virtual-prepaid-card-feature-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-evolution-of-saccos-after-covid-19-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-evolution-of-saccos-after-covid-19/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-evolution-of-saccos-after-covid-19-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-onboarding-for-seamless-banking-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-onboarding-for-seamless-banking-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-onboarding-for-seamless-banking-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-payment-frauds-during-covid-19-and-how-to-fight-them-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-payment-frauds-during-covid-19-and-how-to-fight-them/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-payment-frauds-during-covid-19-and-how-to-fight-them-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-payment-without-internet-and-smartphones-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-payment-without-internet-and-smartphones/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-payment-without-internet-and-smartphones-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-fraud-and-its-prevention-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-wallet-fraud-and-its-prevention/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-fraud-and-its-prevention-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-impact-and-benefits-on-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-wallet-impact-and-benefits-on-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-impact-and-benefits-on-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-security-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-wallet-security/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-security-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-solution-vs-wallet-as-a-service-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digital-wallet-solution-vs-wallet-as-a-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-wallet-solution-vs-wallet-as-a-service-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digitization-of-international-money-transfer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/digitization-of-international-money-transfer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digitization-of-international-money-transfer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-dominating-cross-border-money-transfer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/dominating-cross-border-money-transfer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-dominating-cross-border-money-transfer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ekyc-verification-trends-in-2024-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/ekyc-verification-trends-in-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ekyc-verification-trends-in-2024-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-electronic-payment-systems-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/electronic-payment-systems/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-electronic-payment-systems-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-elevate-businesses-with-cross-border-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/elevate-businesses-with-cross-border-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-elevate-businesses-with-cross-border-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-emergence-of-open-banking-and-api-integration-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/emergence-of-open-banking-and-api-integration/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-emergence-of-open-banking-and-api-integration-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-enhancing-guest-experience-with-prepaid-card-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/enhancing-guest-experience-with-prepaid-card-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-enhancing-guest-experience-with-prepaid-card-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-enriching-digital-payments-with-qr-codes-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/enriching-digital-payments-with-qr-codes/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-enriching-digital-payments-with-qr-codes-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-essential-features-of-ideal-merchant-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/essential-features-of-ideal-merchant-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-essential-features-of-ideal-merchant-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-evaluating-security-and-compliance-of-merchant-payment-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/evaluating-security-and-compliance-of-merchant-payment-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-evaluating-security-and-compliance-of-merchant-payment-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-know-about-neobank-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/everything-you-need-know-about-neobank/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-know-about-neobank-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-know-about-qr-code-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/everything-you-need-to-know-about-qr-code-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-know-about-qr-code-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-evolution-of-financial-inclusion-in-east-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/evolution-of-financial-inclusion-in-east-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-evolution-of-financial-inclusion-in-east-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ewallets-are-more-popular-developing-countries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/ewallets-are-more-popular-developing-countries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ewallets-are-more-popular-developing-countries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-face-recognition-in-ekyc-identity-verification-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/face-recognition-in-ekyc-identity-verification/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-face-recognition-in-ekyc-identity-verification-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fast-and-secure-payment-processing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fast-and-secure-payment-processing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fast-and-secure-payment-processing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fast-secure-customer-onboarding-with-digipay-guru-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fast-secure-customer-onboarding-with-digipay-guru/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fast-secure-customer-onboarding-with-digipay-guru-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-features-for-international-remitttance-success-for-banks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/features-for-international-remitttance-success-for-banks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-features-for-international-remitttance-success-for-banks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-boom-in-latin-america-stats-trends-future-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fintech-boom-in-latin-america-stats-trends-future/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-boom-in-latin-america-stats-trends-future-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-impact-on-international-remittance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fintech-impact-on-international-remittance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-impact-on-international-remittance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-solutions-transforming-the-banking-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fintech-solutions-transforming-the-banking-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fintech-solutions-transforming-the-banking-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fintechs-combat-fraud-and-enhance-user-satisfcation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/fintechs-combat-fraud-and-enhance-user-satisfcation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fintechs-combat-fraud-and-enhance-user-satisfcation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-banking-with-digital-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-banking-with-digital-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-banking-with-digital-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-cross-border-remittance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-cross-border-remittance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-cross-border-remittance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-customer-onboarding-in-banks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-customer-onboarding-in-banks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-customer-onboarding-in-banks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-ewallets-with-nfc-and-qr-code-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-ewallets-with-nfc-and-qr-code-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-ewallets-with-nfc-and-qr-code-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-innovations-in-digital-payment-infrastructure-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-innovations-in-digital-payment-infrastructure/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-innovations-in-digital-payment-infrastructure-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-merchant-payment-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-merchant-payment-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-merchant-payment-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-payments-with-virtual-prepaid-cards-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-of-payments-with-virtual-prepaid-cards/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-of-payments-with-virtual-prepaid-cards-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-future-trends-in-merchant-payment-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/future-trends-in-merchant-payment-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-future-trends-in-merchant-payment-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-government-to-person-payments-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/government-to-person-payments-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-government-to-person-payments-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-choose-ekyc-solution-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/guide-to-choose-ekyc-solution-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-choose-ekyc-solution-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-merchant-payment-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/guide-to-merchant-payment-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-merchant-payment-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hotel-payments-with-prepaid-card-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/hotel-payments-with-prepaid-card-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hotel-payments-with-prepaid-card-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-automated-kyc-solutions-reduce-cost-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-automated-kyc-solutions-reduce-cost/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-automated-kyc-solutions-reduce-cost-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-biometrics-is-driving-security-in-digital-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-biometrics-is-driving-security-in-digital-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-biometrics-is-driving-security-in-digital-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-digital-wallet-api-solution-make-financial-services-efficient-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-digital-wallet-api-solution-make-financial-services-efficient/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-digital-wallet-api-solution-make-financial-services-efficient-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-e-wallets-changing-retail-shopping-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-eWallets-changing-retail-shopping-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-e-wallets-changing-retail-shopping-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-ekyc-helps-banks-serve-more-customers-in-rural-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-ekyc-helps-banks-serve-more-customers-in-rural-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-ekyc-helps-banks-serve-more-customers-in-rural-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-international-money-transfer-software-helps-mto-and-banks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-international-money-transfer-software-helps-mto-and-banks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-international-money-transfer-software-helps-mto-and-banks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-payments-can-combat-coronavirus-pandemic-and-help-the-world-adapt-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-payments-can-combat-coronavirus-pandemic-and-help-the-world-adapt/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-payments-can-combat-coronavirus-pandemic-and-help-the-world-adapt-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-set-up-digital-wallet-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-to-set-up-digital-wallet-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-set-up-digital-wallet-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-virtual-cards-work-and-their-benefits-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/how-virtual-cards-work-and-their-benefits/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-virtual-cards-work-and-their-benefits-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-identity-verification-process-with-face-verification-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/identity-verification-process-with-face-verification/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-identity-verification-process-with-face-verification-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-agency-banking-on-unbanked-population-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/impact-of-agency-banking-on-unbanked-population/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-agency-banking-on-unbanked-population-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-covid-19-on-payment-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/impact-of-covid-19-on-payment-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-covid-19-on-payment-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-digital-onboarding-on-customer-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/impact-of-digital-onboarding-on-customer-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-digital-onboarding-on-customer-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-mobile-money-on-sub-sahara-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/impact-of-mobile-money-on-sub-sahara-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-mobile-money-on-sub-sahara-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-implementing-ekyc-solutions-for-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/implementing-ekyc-solutions-for-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-implementing-ekyc-solutions-for-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-ekyc-in-global-financial-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/importance-of-ekyc-in-global-financial-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-ekyc-in-global-financial-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importants-of-digital-payment-in-smart-city-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/importants-of-digital-payment-in-smart-city/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importants-of-digital-payment-in-smart-city-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-increase-revenue-with-cross-border-payment-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/increase-revenue-with-cross-border-payment-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-increase-revenue-with-cross-border-payment-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-increasing-use-of-ai-and-ml-in-financial-service-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/increasing-use-of-ai-and-ml-in-financial-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-increasing-use-of-ai-and-ml-in-financial-service-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-international-remittance-challenges-solutions-during-covid-19-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/international-remittance-challenges-solutions-during-covid-19/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-international-remittance-challenges-solutions-during-covid-19-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-international-remittance-management-software-for-cross-border-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/international-remittance-management-software-for-cross-border-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-international-remittance-management-software-for-cross-border-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-key-features-for-customer-excellence-in-digital-payment-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/key-features-for-customer-excellence-in-digital-payment-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-key-features-for-customer-excellence-in-digital-payment-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-merchants-guide-to-closed-loop-wallets-and-contactless-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/merchants-guide-to-closed-loop-wallets-and-contactless-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-merchants-guide-to-closed-loop-wallets-and-contactless-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mitigating-security-concerns-in-merchant-payment-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/mitigating-security-concerns-in-merchant-payment-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mitigating-security-concerns-in-merchant-payment-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-banking-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/mobile-banking-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-banking-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-money-and-fintech-success-stories-of-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/mobile-money-and-fintech-success-stories-of-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-money-and-fintech-success-stories-of-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-money-increase-revenue-and-customer-satisfaction-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/mobile-money-increase-revenue-and-customer-satisfaction/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-money-increase-revenue-and-customer-satisfaction-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-money-transfer-software-in-global-remittance-market-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/money-transfer-software-in-global-remittance-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-money-transfer-software-in-global-remittance-market-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mpos-solution-for-retail-store-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/mpos-solution-for-retail-store/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mpos-solution-for-retail-store-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-need-of-fintech-solutions-for-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/need-of-fintech-solutions-for-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-need-of-fintech-solutions-for-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-neobanking-future-trends-opportunities-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/neobanking-future-trends-opportunities/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-neobanking-future-trends-opportunities-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-omnichannel-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/omnichannel-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-omnichannel-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-online-payment-in-digital-wallet-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/online-payment-in-digital-wallet/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-online-payment-in-digital-wallet-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-online-payment-solution-for-small-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/online-payment-solution-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-online-payment-solution-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-open-banking-changing-the-payments-landscape-worldwide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/open-banking-changing-the-payments-landscape-worldwide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-open-banking-changing-the-payments-landscape-worldwide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-opportunities-and-trends-in-future-of-agency-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/opportunities-and-trends-in-future-of-agency-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-opportunities-and-trends-in-future-of-agency-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-opportunities-challenges-for-mobile-money-in-middle-east-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/opportunities-challenges-for-mobile-money-in-middle-east/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-opportunities-challenges-for-mobile-money-in-middle-east-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-overcome-challenges-of-cross-border-digital-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/overcome-challenges-of-cross-border-digital-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-overcome-challenges-of-cross-border-digital-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-overcoming-challenges-in-implementing-digital-wallet-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/overcoming-challenges-in-implementing-digital-wallet/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-overcoming-challenges-in-implementing-digital-wallet-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-past-present-and-future-of-microfinance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/past-present-and-future-of-microfinance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-past-present-and-future-of-microfinance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-peer-to-peer-payment-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/peer-to-peer-payment-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-peer-to-peer-payment-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-phone-to-phone-payments-with-hce-in-ewallet-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/phone-to-phone-payments-with-hce-in-ewallet/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-phone-to-phone-payments-with-hce-in-ewallet-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-pillars-of-digital-bank-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/pillars-of-digital-bank/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-pillars-of-digital-bank-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-card-management-software-for-retail-chains-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/prepaid-card-management-software-for-retail-chains/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-card-management-software-for-retail-chains-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-card-management-system-for-financial-institutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/prepaid-card-management-system-for-financial-institutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-card-management-system-for-financial-institutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-cards-vs-digital-wallets-a-perfect-match-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/prepaid-cards-vs-digital-wallets-a-perfect-match/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-cards-vs-digital-wallets-a-perfect-match-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-gift-cards-to-boost-retail-sales-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/prepaid-gift-cards-to-boost-retail-sales/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prepaid-gift-cards-to-boost-retail-sales-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prevent-fraud-with-kyc-aml-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/prevent-fraud-with-kyc-aml/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prevent-fraud-with-kyc-aml-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-based-cashless-payment-solution-for-amusement-parks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/rfid-based-cashless-payment-solution-for-amusement-parks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-based-cashless-payment-solution-for-amusement-parks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-contactless-payments-and-their-impact-on-retail-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/rise-of-contactless-payments-and-their-impact-on-retail/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-contactless-payments-and-their-impact-on-retail-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-digital-currencies-and-impact-on-traditional-banking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/rise-of-digital-currencies-and-impact-on-traditional-banking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-digital-currencies-and-impact-on-traditional-banking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-e-wallet-solutions-in-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/role-e-wallet-solutions-in-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-e-wallet-solutions-in-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-based-digital-wallet-solution-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/saas-based-digital-wallet-solution-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-based-digital-wallet-solution-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scale-business-with-digital-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/scale-business-with-digital-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scale-business-with-digital-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-schedule-payment-for-fintech-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/schedule-payment-for-fintech-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-schedule-payment-for-fintech-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-seamless-middle-east-2022-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/seamless-middle-east-2022/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-seamless-middle-east-2022-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-secure-customer-onboarding-with-ekyc-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/secure-customer-onboarding-with-ekyc-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-secure-customer-onboarding-with-ekyc-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-secure-payment-processing-with-tokenization-technology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/secure-payment-processing-with-tokenization-technology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-secure-payment-processing-with-tokenization-technology-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-secure-your-online-payment-system-for-your-customers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/secure-your-online-payment-system-for-your-customers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-secure-your-online-payment-system-for-your-customers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-security-concerns-and-solutions-in-digital-payment-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/security-concerns-and-solutions-in-digital-payment/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-security-concerns-and-solutions-in-digital-payment-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-security-threats-of-mobile-financial-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/security-threats-of-mobile-financial-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-security-threats-of-mobile-financial-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-strategies-to-build-a-digital-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/strategies-to-build-a-digital-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-strategies-to-build-a-digital-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-strategies-to-reduce-remittance-costs-for-providers-and-customers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/strategies-to-reduce-remittance-costs-for-providers-and-customers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-strategies-to-reduce-remittance-costs-for-providers-and-customers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-identity-verification-process-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/streamline-identity-verification-process/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-identity-verification-process-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-sub-wallets-and-their-need-for-your-customers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/sub-wallets-and-their-need-for-your-customers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-sub-wallets-and-their-need-for-your-customers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-telcos-mobile-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/telcos-mobile-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-telcos-mobile-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-impact-of-fintech-on-banks-and-financial-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/the-impact-of-fintech-on-banks-and-financial-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-impact-of-fintech-on-banks-and-financial-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-role-of-iso-20022-in-real-time-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/the-role-of-iso20022-in-real-time-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-role-of-iso-20022-in-real-time-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-things-traditional-bank-can-learn-from-neo-banks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/things-traditional-bank-can-learn-from-neo-banks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-things-traditional-bank-can-learn-from-neo-banks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-make-digital-payments-effective-and-user-friendly-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/tips-to-make-digital-payments-effective-and-user-friendly/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-make-digital-payments-effective-and-user-friendly-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-contactless-payments-trends-to-watch-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/top-contactless-payments-trends-to-watch/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-contactless-payments-trends-to-watch-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-digital-payment-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/top-digital-payment-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-digital-payment-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-fintech-industry-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/top-fintech-industry-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-fintech-industry-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-fintech-predictions-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/top-fintech-predictions-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-fintech-predictions-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-prediction-for-prepaid-card-solutions-2024-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/top-prediction-for-prepaid-card-solutions-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-prediction-for-prepaid-card-solutions-2024-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-transparency-speed-and-security-in-cross-border-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/transparency-speed-and-security-in-cross-border-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-transparency-speed-and-security-in-cross-border-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-understanding-currency-corridors-in-remittance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/understanding-currency-corridors-in-remittance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-understanding-currency-corridors-in-remittance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-unique-features-of-digital-wallet-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/unique-features-of-digital-wallet-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-unique-features-of-digital-wallet-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-use-cases-of-ai-and-ml-in-fintech-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/use-cases-of-ai-and-ml-in-fintech/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-use-cases-of-ai-and-ml-in-fintech-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-video-kyc-solution-for-banks-and-financial-institutes-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/video-kyc-solution-for-banks-and-financial-institutes/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-video-kyc-solution-for-banks-and-financial-institutes-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-virtual-cards-for-enhanced-customer-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/virtual-cards-for-enhanced-customer-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-virtual-cards-for-enhanced-customer-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-virtual-cards-for-your-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/virtual-cards-for-your-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-virtual-cards-for-your-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-a-white-label-digital-bank-definition-features-and-benefits-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-a-white-label-digital-bank-definition-features-and-benefits-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-a-white-label-digital-bank-definition-features-and-benefits-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-agent-bank-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-agent-bank/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-agent-bank-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-card-issuing-and-how-does-it-work-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-card-issuing-and-how-does-it-work/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-card-issuing-and-how-does-it-work-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-cross-border-fees-in-remittance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-cross-border-fees-in-remittance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-cross-border-fees-in-remittance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-merchant-accounts-and-how-its-works-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-merchant-accounts-and-how-its-works/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-merchant-accounts-and-how-its-works-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-merchant-payment-processing-and-how-it-benefits-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/what-is-merchant-payment-processing-and-how-it-benefits-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-is-merchant-payment-processing-and-how-it-benefits-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-money-transfer-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/white-label-money-transfer-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-money-transfer-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-payment-solution-and-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/digipay-react-website/content/blog/white-label-payment-solution-and-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-payment-solution-and-platform-index-mdx" */),
  "component---src-templates-case-study-single-js": () => import("./../../../src/templates/case-study-single.js" /* webpackChunkName: "component---src-templates-case-study-single-js" */)
}

